import React, { useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  TextField, 
  Button, 
  Typography, 
  Container, 
  Grid, 
  Paper 
} from '@mui/material';
import Cookies from 'js-cookie'; // Import js-cookie

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '', // Adjust field names based on swagger.json
    password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 1. Make API request to your login endpoint
      const response = await axios.post('https://api.antarespharmacy.ru/api/v1/authorization/login', formData); // Update endpoint URL

      // 2. Handle successful login (assuming JWT authentication)
      if (response.status === 200) {
        const apiKey = response.data.apiKey; // Assuming 'token' holds the apiKey

        // Store apiKey in a cookie
        Cookies.set('apiKey', apiKey, { expires: 1 }); // Cookie expires in 7 days

        navigate('/'); 
      } else {
        // Handle unexpected response
        console.error('Login failed:', response);
        navigate('/login'); 
      }
    } catch (error) {
      // 3. Handle login errors (e.g., invalid credentials)
      console.error('Login error:', error);
      // Display error message to the user
    }
  };

  return (
    <Container maxWidth="xm">
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h5" align="center" gutterBottom>
              Login to Pharmacy Store
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Email"
                name="email"
                type="email"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                label="Password"
                name="password"
                type="password"
                fullWidth
                margin="normal"
                value={formData.password}
                onChange={handleChange}
              />
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Login
              </Button> 
              <br /> 
              Or
              <br />
              <a href="https://api.antarespharmacy.ru/swagger" variant="contained" color="success" fullWidth>
                Swagger
              </a>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;