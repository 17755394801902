import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPharmacyById, getPharmacyReviews } from '../services/api'; 
import { Typography, List, ListItem, ListItemText } from '@mui/material';

const PharmacyDetails = () => {
  const { id } = useParams(); 
  const [pharmacy, setPharmacy] = useState(null);
  const [reviews, setReviews] = useState([]); // State for reviews

  useEffect(() => {
    const fetchPharmacyDetails = async () => {
      try {
        const data = await getPharmacyById(id); 
        setPharmacy(data); 
      } catch (error) {
        console.error('Error fetching pharmacy details:', error);
      }
    };

    const fetchPharmacyReviews = async () => {
        try {
            const data = await getPharmacyReviews();
            setReviews(data); // TODO: Filter reviews by pharmacy ID later
        } catch (error) {
            console.error('Error fetching pharmacy reviews:', error);
        }
    };

    fetchPharmacyDetails();
    fetchPharmacyReviews(); // Fetch reviews as well
  }, [id]); // Fetch when 'id' changes

  if (!pharmacy) {
    return <div>Loading pharmacy details...</div>; // Or a loading indicator
  }

  return (
    <div>
      <p>Pharmacy ID: {pharmacy.id}</p>
      <h1>{pharmacy.city}</h1>
      <p>Address: {pharmacy.address}</p>
      <p>Phone: {pharmacy.phone}</p>
      <p>Long: {pharmacy.long}</p>
      <p>Lat: {pharmacy.lattitude}</p>
      <p>Region: {pharmacy.region}</p>

      <Typography variant="h6" gutterBottom>
        Reviews:
      </Typography>

      {reviews.length === 0 ? (
        <Typography variant="body2">No reviews yet.</Typography>
      ) : (
        <List>
          {reviews.map((review) => (
            <ListItem key={review.id}>
              <ListItemText
                primary={review.comment} 
                secondary={`Rating: ${review.rating}`} 
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default PharmacyDetails;