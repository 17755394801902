import React, { useState, useEffect } from 'react';
import { getProducts } from '../../services/api'; // Import your API function
import { 
  Grid, 
  Typography, 
  Paper, 
  CircularProgress,
  Link,
  Button 
} from '@mui/material';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const data = await getProducts();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div>
        <Typography variant="h4" align="center" gutterBottom>
        Products
        </Typography>

        {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <CircularProgress /> 
        </Grid>
        ) : (
        <Grid container spacing={2}>
            {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
                <Link href={`/product/${product.product_id}`} underline="none"> {/* Assuming you have a ProductDetails page */}
                <Paper elevation={3} style={{ padding: '16px', textDecoration: 'none' }}> 
                    <Typography variant="subtitle1">{product.name}</Typography>
                    {product.contry && <Typography variant="subtitle2">Country: {product.contry}</Typography>} {/* Conditionally render country */}
                    <Typography variant="body2">{product.manual}</Typography>
                    Bonus: <Typography variant="body2">{product.bonus}</Typography> 
                    {/* Add more relevant pharmacy product details */}
                </Paper>
                </Link>
            </Grid>
            ))}
        </Grid>
        )}
    </div>
  );
};

export default ProductsPage;