import axios from 'axios';
import Cookies from 'js-cookie'; // Import js-cookie

const api = axios.create({
  baseURL: 'https://api.antarespharmacy.ru/api/v1', // Your API base URL
});

const setToken = () => {
    const token = Cookies.get('apiKey');
    if(token !== undefined || token != null)
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

// Example API function
export const getProducts = async () => {
  try {
    setToken();
    const response = await api.get('/pharmacy/product');
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error; // Handle errors appropriately
  }
};

export const getProductById = async (id) => {
    try {
        setToken();
        const response = await api.get(`/pharmacy/product/${id}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching details of product:', error);
        throw error; // Handle errors appropriately
      }
}

// Example API function
export const getPharmacies = async () => {
    try {
      setToken();
      const response = await api.get('/pharmacy');
      return response.data;
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error; // Handle errors appropriately
    }
};

export const getPharmacyById = async (id) => {
    try {
      setToken();
      const response = await api.get(`/pharmacy/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching pharmacy with ID ${id}:`, error);
      throw error; 
    }
};

export const getPharmacyReviews = async () => {
    try {
        setToken();
        const response = await api.get(`/pharmacy/pharmacyReview`);
        return response.data;
      } catch (error) {
        console.error(`Error fetching pharmacy reviews:`, error);
        throw error; 
      }
}
