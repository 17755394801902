import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import Cookies from 'js-cookie';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove('apiKey'); 
    navigate('/login'); 
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" alignItems="center" width="100%"> {/* Use Box for layout */}
          <Box> {/* Container for links on the left */}
            <Button color="inherit" component={Link} to="/" sx={{ mr: 2 }}> {/* Add margin-right */}
              Home
            </Button>
            <Button color="inherit" component={Link} to="/products">
              Products
            </Button>
          </Box>
          
          <Typography variant="h6" style={{ flexGrow: 1 }}> 
            Pharmacy Store
          </Typography>

          <Box ml={2}> {/* Logout button on the right with margin-left */}
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;