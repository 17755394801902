import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getProductById } from '../../services/api'; // Assuming you have an API function to fetch a single product by ID
import { Typography, Paper } from '@mui/material';

const ProductDetails = () => {
  const { product_id } = useParams(); // Get product ID from URL
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const data = await getProductById(product_id);
        setProduct(data);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [product_id]); // Fetch when 'product_id' changes

  if (!product) {
    return <div>Loading product details...</div>; // Or a loading indicator
  }

  return (
    <div>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Typography variant="h5" gutterBottom>{product.name}</Typography>
        {product.contry && <Typography variant="subtitle1">Country: {product.contry}</Typography>}
        <Typography variant="body1" gutterBottom>Manual: {product.manual}</Typography>
        <Typography variant="subtitle1">Bonus: {product.bonus}</Typography>
        {product.availability && <Typography variant="subtitle1">Availabile: YES</Typography>}
        {/* Add more product details as needed */}
      </Paper>
    </div>
  );
};

export default ProductDetails;