import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login'; // Assuming your Login component is in /pages/Login
import Home from './pages/Home'; // Assuming your Home component is in /pages/Home
import PharmacyDetails from './pages/PharmacyDetails';
import ProductsPage from './pages/Products';
import Cookies from 'js-cookie';
import ProductDetails from './pages/Products/details';
import Navbar from './components/Navbar'; // Import the Navbar component

function App() {
  const apiKey = Cookies.get('apiKey');

  return (
    <BrowserRouter>
      <div className="App">
        {apiKey && <Navbar />}

        <Routes>
          <Route 
            path="/" 
            element={apiKey ? (
              // Render your main app content if apiKey exists
              <div>
                <Home />
              </div> 
            ) : (
              // Redirect to login if apiKey is missing or empty
              <Navigate to="/login" /> 
            )} 
          />
          <Route path="/login" element={<Login />} />
          <Route path="/pharmacy/:id" element={<PharmacyDetails />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/product/:product_id" element={<ProductDetails />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
