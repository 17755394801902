import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Paper } from '@mui/material';
import { getPharmacies } from '../../services/api'; // Assuming you have an API function to fetch pharmacies

const Home = () => {
  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const data = await getPharmacies(); // Fetch pharmacies from your API
        setPharmacies(data.slice(0, 20)); // Limit to 20 pharmacies
      } catch (error) {
        console.error('Error fetching pharmacies:', error);
      }
    };

    fetchPharmacies();
  }, []);

  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
        Pharmacies:
      </Typography>

      <Grid container spacing={2}>
        {pharmacies.map((pharmacy) => (
          <Grid item xs={12} sm={6} md={3} key={pharmacy.id}> 
            <Link to={`/pharmacy/${pharmacy.id}`} style={{ textDecoration: 'none' }}>
              <Paper elevation={3} style={{ padding: '16px', textAlign: 'center' }}>
                <Typography variant="subtitle1">{pharmacy.city}</Typography>
                <Typography variant="body2">
                  {pharmacy.address}
                </Typography>
                <Typography variant="subtitle2">
                  {pharmacy.phone}
                </Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Home;